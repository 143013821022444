<template>
    <el-card class="box-card-b" shadow="never">
        <el-form :model="changeForms" :inline="true" label-position="top">
            <el-row>
                <el-col v-for="(item, index) in changeHeads" :key="index" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                    <el-form-item :label="item.label" label-position="left" label-width='100%'>
                        <el-input v-model="changeForms[item.property]" v-if="item.type === 'number'" type="number"
                            :disabled='!item.edit' />
                        <el-input v-model="changeForms[item.property]" v-if="item.type === 'input'"
                            :disabled='!item.edit' />
                        <el-input v-model="changeForms[item.property]" :rows="2" type="textarea"
                            v-if="item.type === 'textarea'" :disabled='!item.edit' />
                        <el-select v-model="changeForms[item.property]" placeholder="选择" v-if="item.type === 'select'"
                            filterable :disabled='!item.edit'>
                            <el-option v-for="i in item.option" :key="i" :label="i" :value="i" />
                        </el-select>
                        <el-date-picker v-model="changeForms[item.property]" type="date" placeholder="选择时间"
                            format="YYYY-MM-DD" value-format="YYYY-MM-DD" range-separator="-"
                            v-if="item.type === 'date-picker'" :disabled='!item.edit' />
                        <el-date-picker v-model="changeForms[item.property]" type="date" placeholder="选择时间"
                            format="YYYY-MM-DD" value-format="YYYY-MM-DD" range-separator="-"
                            v-if="item.type === 'one-date-picker'" :disabled='!item.edit' />
                        <el-cascader v-model="changeForms[item.property]" :options="item.option" :props="props"
                            collapse-tags clearable v-if="item.type === 'cascader'" :disabled='!item.edit' />
                        <el-input v-model="changeForms[item.property]" :rows="2" v-if="item.type === 'disabled'"
                            disabled />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span class="dialog-footer">
            <!-- <el-button @click="cancels">取消</el-button> -->
            <!-- <el-button type="danger" @click="deletes">删除</el-button> -->
            <el-button type="primary" @click="updatas">
                确认
            </el-button>
        </span>
    </el-card>

</template>

<script>
    import axios from "@/utils/request";
    import router from '@/router'
    import {
        ElMessage,
        ElLoading,
    } from 'element-plus'
    export default {
        name: 'MimiForm',
        data() {
            return {
                changeForms: [],
                changeHeads: [],
            }
        },
        created() {
            axios({
                method: "post",
                url: 'mimiform',
                data: this.$route.query,
            }).then(res => {
                this.changeForms = res.data //数据本身
                this.changeHeads = res.tableHead.tableHead //数据表头
            })
        },
        methods: {
            updatas() {
                axios({
                    method: "post",
                    url: 'mimiform',
                    data: this.changeForms,
                }).then(res => {
                    ElMessage({
                        type: "success",
                        message: `操作成功！`,
                    })
                })
            }
        }
    }
</script>

<style scoped>
</style>